import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const ConstructionTechnologyFestivalKsa: React.FC = () => {
  return (
    <>
      <SEO
        title="Construction Technology Festival KSA"
        description="Construction Technology Festival KSA will be held in Riyadh (Kingdom Saudi Arabia), December 14-15. SIGNAX is among the main participants."
      />
      <NewsSectionBlock date="17.11.2022">
        <Typography variant="h1" color="blue">
          Construction Technology Festival KSA, December 14-15
        </Typography>
        <div className={s.imageWrap}>
          <div className="flex">
            <StaticImage
              width={580}
              className="m-2"
              src="../assets/images/news/2022-11-17-ctf-ksa/image-1.png"
              alt="Construction Technology Festival KSA"
              title="Construction Technology Festival KSA"
              placeholder="blurred"
            />
            <StaticImage
              width={580}
              className="m-2"
              src="../assets/images/news/2022-11-17-ctf-ksa/image-2.png"
              alt="Construction Technology Festival KSA 2"
              title="Construction Technology Festival KSA 2"
              placeholder="blurred"
            />
          </div>
        </div>
        <Typography variant="body1">
          <strong>Construction Technology Festival KSA</strong> will be held in
          Riyadh (Kingdom Saudi Arabia), December 14-15. SIGNAX is among the
          main participants.
        </Typography>
        <Typography variant="body1">
          CTS brings together the Kingdom’s key government authorities, project
          developers, contractors, consultants and Contech companies to share
          digital use-cases that design, build and operate major projects
          faster, cheaper and more efficiently.
        </Typography>
        <Typography variant="body1">
          SIGNAX has multiple roles: speaker, exhibitor & technology workshop
          facilitator. Main topic of presentation is «BIM for owners. Remote
          supervision of site for enhanced quality, cost, safety and
          construction dynamics control». Government and commercial owners,
          investors and developers, both real estate and industrial, are the
          main stakeholders and beneficiary of using BIM and digital data on
          site. Thanks to innovative technologies it is already available and
          can give a great profit today.
        </Typography>
        <Typography variant="body1">
          SIGNAX team invites to «BIM for Developer and Contractors on site
          workshop». You will understand how digital construction processes look
          like and what the difference between them and existing ones is. Then
          we will focus on each kind of work: checking the quality of data,
          calculation of volumes & budgets, control of subcontractors, issues,
          acceptance of performed works, remote supervision using photo 360,
          laser scanning devices and UAV. As a result, you will understand what
          is the profit of using digital data on site and how you can start.
        </Typography>
        <Typography variant="body1">
          As usual, you are invited to visit our stand and look how innovations
          can work on construction site. You will watch the live demo of SIGNAX
          modules and see how they are implemented in iconic projects. See you
          in Riyadh!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default ConstructionTechnologyFestivalKsa
